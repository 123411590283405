<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>结项</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-merchants-info">
            <span>结项</span>
            <div class="sub-merchants-container">
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议名称',pid.cid)}}</div>
                    <el-select
                        v-model="pName"
                        filterable
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        @change="proChange">
                        <el-option
                            v-for="(item, index) in projectOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="index"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">所属学会</div>
                    <el-select
                        v-model="society"
                        :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                        @visible-change="clickProject">
                        <el-option
                            v-for="item in societyOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议日期',pid.cid)}}</div>
                    <el-input
                        v-model="pid.time"
                        disabled
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('大会主席',pid.cid)}}</div>
                    <el-input
                        v-model="pid.chairman"
                        disabled
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                    ></el-input>
                </div>

                <!--                <div class="input-item">-->
                <!--                    <div class="input-item-title">付款项目</div>-->
                <!--                    <el-input v-model="pay_item" placeholder="请输入付款项目"></el-input>-->
                <!--                </div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">付款金额</div>-->
                <!--<el-input v-model="pay_amount" type="number" placeholder="请输入付款金额"></el-input><div class="money">万元</div>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">收款单位</div>-->
                <!--<el-input v-model="payee" placeholder="请输入收款单位"></el-input>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">收款单位开户行</div>-->
                <!--<el-input v-model="bank_name"  placeholder="请输入收款单位开户行"></el-input>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">收款单位账号</div>-->
                <!--<el-input v-model="bank_code" type="number" placeholder="请输入收款单位账号"></el-input>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-desc">费用明细单</div>-->
                <!--<el-input  v-model="detail" placeholder="请输入费用明细单"></el-input>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">付款交接文件</div>-->
                <!--<el-checkbox-group v-model="fukuan_jiaojie">-->
                <!--<el-checkbox label="1">支出凭单</el-checkbox>-->
                <!--<el-checkbox label="2">下游专票</el-checkbox>-->
                <!--<el-checkbox label="3">下游协议</el-checkbox>-->
                <!--<el-checkbox label="4">项目明细</el-checkbox>-->
                <!--<el-checkbox label="5">付款确认单</el-checkbox>-->
                <!--</el-checkbox-group>-->
                <!--</div>-->

                <!--<div class="input-item">-->
                <!--<div class="input-item-title">结算交接文件</div>-->
                <!--<el-checkbox-group v-model="jiesuan_jiaojie">-->
                <!--<el-checkbox label="1">会议结算单</el-checkbox>-->
                <!--<el-checkbox label="2">电子结算文件</el-checkbox>-->
                <!--</el-checkbox-group>-->
                <!--</div>-->
                <div class="input-item">
                    <div class="input-item-desc">备注</div>
                    <el-input
                        v-model="remark"
                        :rows="5"
                        placeholder="请输入备注"
                        resize="none"
                        type="textarea"
                    ></el-input>
                </div>
                <approvalList
                    :approvalMembers="approvalMembers"
                    :copyMembers="copyMembers"
                    :isNoHave="!id"
                    :society="society"
                    :typeClassify="8"
                    @setApproval="setApproval"
                    @setMember="setMember"
                ></approvalList>

                <el-button :disabled="requestState" :loading="requestState" type="primary" @click="subApproval">提交
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import approvalList from "../../components/approvalList.vue";

export default {
    components: {
        ElButton,
        ElInput,
        approvalList,
    },
    name: "fpr",
    data() {
        return {
            id: "",
            sign_code: "",
            //  学会数据
            society: "",
            societyOptions: [],
            //  会议数据
            pid: "",
            pName: "",
            projectOptions: [],
            pay_item: "",
            pay_amount: "",
            payee: "",
            fukuan_jiaojie: [],
            jiesuan_jiaojie: [],
            detail: "",
            shiyou: "",
            bank_name: "",
            bank_code: "",
            remark: "",

            shenpi: "",
            copy: "",
            approvalMembers: [],
            copyMembers: [],
            requestState: false
        };
    },
    created() {
        this.id = this.$route.query.id ? this.$route.query.id : '';
        this.$emit("setIndex", [4, 15]);
        this.init();
    },
    methods: {
        //  提交审核
        subApproval() {
            if (!this.pid) {
                this.$message.error("请选择会议名称");
                return;
            }
            if (!this.society) {
                this.$message.error("请选择所属学会");
                return;
            }
            // if (!this.pay_item) {
            //     this.$message.error("请输入付款项目")
            //     return
            // }
            //                if (!this.pay_amount) {
            //                    this.$message.error("请选择付款金额")
            //                    return
            //                }
            //                if (!this.payee) {
            //                    this.$message.error("请选择收款单位")
            //                    return
            //                }
            //                if (!this.bank_name) {
            //                    this.$message.error("请输入开户行")
            //                    return
            //                }
            //                if (!this.bank_code) {
            //                    this.$message.error("请输入银行账户")
            //                    return
            //                }
            //                if (!this.detail) {
            //                    this.$message.error("请输入费用明细单")
            //                    return
            //                }
            //                if (this.fukuan_jiaojie.length===0) {
            //                    this.$message.error("请选择付款交接文件")
            //                    return
            //                }
            //                if (this.jiesuan_jiaojie.length===0) {
            //                    this.$message.error("请选择结算交接文件")
            //                    return
            //                }
            //                if (!this.remark) {
            //                    this.$message.error("请输入备注")
            //                    return
            //                }
            if (this.shenpi.indexOf("-1") !== -1) {
                this.$message.error("请完善财务人员");
                return;
            }
            let arr = this.shenpi.split(",");
            if (arr[0] === arr[1]) {
                this.$message.error("审批人与财务人员不能相同");
                return;
            }
            this.requestState = true
            //添加
            this.$api.apiContent
                .approvalJX({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sid: this.society,
                    pid: this.pid.id,
                    sign_code: this.sign_code,
                    pay_item: this.pay_item,
                    pay_amount: this.pay_amount,
                    payee: this.payee,
                    bank_name: this.bank_name,
                    bank_no: this.bank_code,
                    detail: this.detail,
                    fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                    jiesuan_jiaojie: this.jiesuan_jiaojie.join(","),
                    remark: this.remark,
                    shenpi: this.shenpi,
                    copy: this.copy,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            sid: this.society,
                            pid: this.pid.id,
                            sign_code: this.sign_code,
                            pay_item: this.pay_item,
                            pay_amount: this.pay_amount,
                            payee: this.payee,
                            bank_name: this.bank_name,
                            bank_no: this.bank_code,
                            detail: this.detail,
                            fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                            jiesuan_jiaojie: this.jiesuan_jiaojie.join(","),
                            remark: this.remark,
                            shenpi: this.shenpi,
                            copy: this.copy,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.requestState = false
                    this.$message.success(res.msg);
                    this.$router.push({
                        name: "fpr",
                        query: {id: res.data.id, type: 1},
                    });
                    //                    this.backPage()
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        //  选择会议
        proChange(index) {
            let value = this.projectOptions[index];
            value.time =
                this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                " 至 " +
                this.$tools.ZHDate(new Date(value.end_time * 1000));
            this.pName = value.name;
            this.pid = value;
            this.society = '';
            this.getSociety();
        },
        //  选择所属学会点开后没选择
        clickProject(view) {
            if (!this.pid && view) {
                this.$message.error("请选择会议名称");
            }
        },
        //  获取项目
        getSociety() {
            this.$api.apiContent
                .getSocietyFromPro({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid.id,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid.id,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.societyOptions = res.data;
                    if (res.data.length === 1 && !this.society) this.society = res.data[0].id;
                })
                .catch(() => {
                    this.societyOptions = [];
                });
        },
        //  获取学会
        init() {
            this.$api.apiContent
                .getProject({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.map(item => {
                        item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                            + this.$tools.ZHDate(new Date(item.end_time * 1000)) + '/' + item.chairman + '/' + item.name
                    })
                    this.projectOptions = res.data;
                })
                .catch(() => {
                    this.projectOptions = [];
                });
            if (this.id) {
                this.$api.apiContent
                    .getJiexiang({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
//            this.approvalMembers = res.data.shenpi;
//            this.shenpi = res.data.shenpi
//              .map((item) => {
//                return item.user_id;
//              })
//              .join(",");
                        this.copyMembers = res.data.copy;
                        this.copy = res.data.copy
                            .map((item) => {
                                return item.user_id;
                            })
                            .join(",");
                        this.society = res.data.sid;
                        this.sign_code = res.data.sign_code;
                        res.data.project.time =
                            this.$tools.ZHDate(
                                new Date(res.data.project.start_time * 1000)) +
                            " 至 " +
                            this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                        res.data.project.id = res.data.pid;
                        this.pid = res.data.project;
                        this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                            + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' + res.data.project.name;
                        this.pay_item = res.data.pay_item;
                        this.pay_amount = res.data.pay_amount;
                        this.payee = res.data.payee;
                        //                        this.fukuan_jiaojie = res.data.fukuan_jiaojie
                        //                        this.jiesuan_jiaojie = res.data.jiesuan_jiaojie
                        this.shiyou = res.data.shiyou;
                        this.detail = res.data.detail;
                        this.bank_name = res.data.bank_name;
                        this.bank_code = res.data.bank_no;
                        this.remark = res.data.remark;
                        this.files = res.data.files;
                        this.getSociety();
                    })
                    .catch(() => {
                    });
            }
        },
        //  返回
        backPage() {
            this.$tools.goBack();
        },
        //审批人
        setApproval(members) {
            this.shenpi = members;
        },
        //抄送人
        setMember(members) {
            this.copy = members;
        },
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .sub-merchants-info {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        padding: 40px 25px;
        min-height: 800px;

        span {
            color: #000000;
            font-size: 24px;
        }

        .sub-merchants-container {
            width: 60%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .input-item {
                display: flex;
                margin-bottom: 20px;
                flex-direction: row;
                align-items: center;
                overflow: hidden;

                .money {
                    flex-shrink: 0;
                    margin-left: 10px;
                }

                .input-item-title {
                    width: 20%;
                    text-align: center;
                    font-size: 16px;
                    margin-right: 20px;
                    color: #000000;
                    flex-shrink: 0;
                }

                .input-item-desc {
                    width: 20%;
                    align-self: flex-start;
                    margin-top: 10px;
                    margin-right: 20px;
                    text-align: center;
                    font-size: 16px;
                    color: #000000;
                    flex-shrink: 0;
                }

                .el-input,
                .el-select,
                .el-textarea,
                .el-radio-group,
                .el-date-editor,
                .el-upload {
                    flex-grow: 1;
                    resize: none;
                }
            }

            > .el-button {
                margin-top: 30px;
                align-self: center;
                width: 40%;
            }
        }
    }
}
</style>
